import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import {projectFunctions} from '../firebase/config';
import store from '../store';
import Swal from 'sweetalert2';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Users/Login.vue')
  },
  {
    path: '/catalog',
    name: 'Catalog',
    component: () => import('../views/Catalog.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import('../views/Contact.vue')
  },
  {
    path: '/drushim',
    name: 'drushim',
    component: () => import('../views/Drushim.vue')
  },
  {
    path: '/branches',
    name: 'Branches',
    component: () => import('../views/Branches.vue')
  },
  {
    path: '/sales_catalog',
    name: 'SalesCatalog',
    component: () => import('../views/SalesCatalog.vue')
  },
  {
    path: '/branches_map',
    name: 'BranchesMap',
    component: () => import('../views/BranchesMap.vue')
  },
  {
    path: '/drushim/:jobId',
    name: 'drushim_landing_page',
    component: () => import('../components/Drushim/JobLandingPage.vue'),
    props: true,
  },
  {
    path: '/routetoday',
    name: 'routetoday',
    component: () => import('../views/AgentsPortal/RouteToday.vue')
  },
  {
    path: '/new-client',
    name: 'new-client',
    component: () => import('../views/AgentsPortal/NewClient.vue')
  },
  {
    path: '/agents_documents',
    name: 'agents_documents',
    component: () => import('../views/AgentsPortal/AgentsDocuments.vue')
  },

  {
    path: '/update-credit-payment',
    name: 'update-credit-payment',
    component: () => import('../views/AgentsPortal/UpdateCreditPayment.vue')
  },
  {
    path: '/wheel',
    name: 'wheel',
    component: () => import('../views/KadamGames/WheelOfFortune.vue')
  },
  {
    path: '/agents',
    name: 'agentportal',
    component: () => import('../views/AgentsPortal.vue'),
    beforeEnter: async (to, from, next) => {
      store.state.loader = true;
      const role = await projectFunctions.httpsCallable('check_role')()
      if(role.data >= 50){
        next()
        store.state.loader = false;
      }
      else{
        next({name: 'Home'})
        store.state.loader = false;
      }
    }
  },
  {
    path: '/agentlocations',
    name: 'agentlocations',
    component: () => import('../views/AgentsPortal/Locations.vue'),
    beforeEnter: async (to, from, next) => {
      store.state.loader = true;
      const role = await projectFunctions.httpsCallable('check_role')()
      if(role.data >= 99){
        next()
        store.state.loader = false;
      }
      else{
        next({name: 'Home'})
        store.state.loader = false;
      }
    }
  },
  {
    path: '/agentalbum',
    name: 'agentalbum',
    component: () => import('../views/AgentsPortal/OpenAlbum.vue'),
    beforeEnter: async (to, from, next) => {
      store.state.loader = true;
      const role = await projectFunctions.httpsCallable('check_role')()
      if(Number(role.data) >= 0){
        next()
        store.state.loader = false;
      }
      else{
        next({name: 'Home'})
        store.state.loader = false;
      }
    }
  },
  {
    path: '/lidim',
    name: 'Lidim',
    component: () => import('../views/AgentsPortal/Lidim.vue'),
    beforeEnter: async (to, from, next) => {
      store.state.loader = true;
      const role = await projectFunctions.httpsCallable('check_role')()
      if(Number(role.data) >= 0){
        next()
        store.state.loader = false;
      }
      else{
        next({name: 'Home'})
        store.state.loader = false;
      }
    }
  },
  {
    path: '/gvia',
    name: 'GviaMain',
    component: () => import('../views/AgentsPortal/GviaMain.vue'),
    beforeEnter: async (to, from, next) => {
      store.state.loader = true;
      const role = await projectFunctions.httpsCallable('check_role')()
      if(Number(role.data) >= 0){
        next()
        store.state.loader = false;
      }
      else{
        next({name: 'Home'})
        store.state.loader = false;
      }
    }
  },
  {
    path: '/success-payment-page',
    name: 'SuccessPaymentPage',
    component: () => import('../components/Agent_portal/Gvia/SuccessPaymentPage.vue'),
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: () => import('../views/Clients/CheckOut.vue'),
    beforeEnter: async (to, from, next) => {
      const user = store.getters.user
      const cart = store.getters.client_cart
      if(!user || !cart){
        Swal.fire({
          icon: 'info',
          title:'אזור מוגבל',
          text: 'הגישה לעמוד זה היא למשתמשים עם הזמנה פתוחה בלבד, הנך מועבר לקטלוג שלנו.'
        })
        next('/catalog')
      }else{
        next()
      }
    }
  },
  {
    path: '/applications',
    name: 'applications',
    component: () => import('../views/Applications.vue'),
  },
  {
    path: '/office-request-main/:id',
    name: 'office-request-main',
    component: () => import('../components/Applications/officeRequests/main/OfficeRequestMain.vue'),
    props: true,
  },
  {
    path: '/manage-request-edit',
    name: 'manage-request-edit',
    component: () => import('../components/Applications/officeRequests/components/manage_side/ManageRequestEdit.vue'),
    props:true
  },
  {
    path: '/shinua-main',
    name: 'shinua-main',
    component: () => import('../components/Applications/shinua/ShinuaMain.vue'),
  },
  {
    path: '/vacation_requests',
    name: 'vacation_requests',
    component: () => import('../components/Applications/vacation_requests/VacationRequestsMain.vue'),
  },
  {
    path: '/update-club-member',
    name: 'update-club-member',
    component: () => import('../components/Applications/update_club_member/UpdateClubMemberMain.vue'),
  },
  {
    path: '/klitat-oved',
    name: 'klitat-oved',
    component: () => import('../components/Applications/klitat_oved/KlitatOvedMain.vue'),
  },
  {
    path: '/yerakot',
    name: 'yerakot',
    component: () => import('../components/Applications/yerakot/YerakotMain.vue'),
  },
  {
    path: '/pickles',
    name: 'pickles',
    component: () => import('../components/Applications/pickles/YerakotMain.vue'),
  },
  {
    path: '/bigud',
    name: 'bigud',
    component: () => import('../components/Applications/bigud/BigudMain.vue'),
  },
  {
    path: '/red-signets',
    name: 'red-signets',
    component: () => import('../components/Applications/red_signets/RedSignetsMain'),
  },
  {
    path: '/event-report',
    name: 'event-report',
    component: () => import('../components/Applications/event_report/EvenetReportMain.vue'),
  },
  {
    path: '/taruchot',
    name: 'taruchot',
    component: () => import('../components/Applications/Taruchot/TaruchotMain.vue'),
  },
  {
    path: '/bikoretshilut',
    name: 'bikoretshilut',
    component: () => import('../components/Applications/bikoretshilut/Bikoretshilut.vue'),
  },
  {
    path: '/check_stock',
    name: 'check_stock',
    component: () => import('../components/Applications/Stocks/CheckStock.vue'),
  },
  {
    path: '/stock_manage',
    name: 'StockManage',
    component: () => import('../components/Applications/Stocks/Admin/StockManage.vue'),
  },
  {
    path: '/st',
    name: 'StockClientMain',
    component: () => import('../components/Applications/Stocks/client/StockClientMain.vue'),
  },
  {
    path: '/documents-main',
    name: 'documents-main',
    component: () => import('../components/Applications/Documents/DocumentsMain.vue'),
  },
  {
    path: '/hr-resource',
    name: 'hr-resource',
    component: () => import('../components/Applications/Hr_Resource/HrResourceMain.vue'),
  },
  {
    path: '/ten_plus_one',
    name: 'ten_plus_one',
    component: () => import('../components/Applications/Ten_plus_one/TenPlusOneMain.vue'),
  },
  {
    path: '/pastramas',
    name: 'pastramas',
    component: () => import('../components/Applications/Pastramas/PastramasMain.vue'),
  },
  {
    path: '/human_resources',
    name: 'HumanResources',
    component: () => import('../components/Applications/HumanResources/HumanResourcesMain.vue'),
  },
  {
    path: '/check-list-manage',
    name: 'CheckListManage',
    component: () => import('../components/Applications/CheckList/components/Admin/CheckListManage.vue'),
  },
  {
    path: '/check-list-main',
    name: 'CheckListMain',
    component: () => import('../components/Applications/CheckList/components/Client/CheckListsMain.vue'),
  },
  {
    path: '/radio-main',
    name: 'RadioMain',
    component: () => import('../components/Applications/Radio/RadioMain.vue'),
  },
  {
    path: '/radio-manage',
    name: 'RadioManage',
    component: () => import('../components/Applications/Radio/Admin/RadioManage.vue'),
  },
  {
    path: '/shilut',
    name: 'Shilut',
    component: () => import('../components/Applications/Shilut/ShilutMain.vue'),
    props:true
  },
  {
    path: '/managments-company',
    name: 'managments_company',
    component: () => import('../components/Applications/managments_company/ManagmentsCompanyMain.vue'),
  },
  {
    path: '/managments-company-admin',
    name: 'managments_company_admin',
    component: () => import('../components/Applications/managments_company/admin/ManagmentsCompanyAdmin.vue'),
  },
  {
    path: '/shilut-yedion',
    name: 'ShilutYedion',
    component: () => import('../components/Applications/Shilut/components/Yedion/YedionMain'),
  },
  {
    path: '/shilut-manage',
    name: 'ShilutManage',
    component: () => import('../components/Applications/Shilut/ShilutDb.vue'),
  },
  {
    path: '/shilut-manage/deals',
    name: 'ShilutDealsManage',
    component: () => import('../components/Applications/Shilut/components/Deals/ShilutDealsDb.vue'),
  },
  {
    path: '/calendar',
    name: 'calendar',
    component: () => import('../components/Applications/Calendar/CalendarMain.vue'),
  },
  {
    path: '/calendar_manage',
    name: 'CalendarManage',
    component: () => import('../components/Applications/Calendar/components/MenageSide/CalendarManage.vue'),
  },
  {
    path: '/comax_client_orders_main',
    name: 'ComaxClientOrdersMain',
    component: () => import('../components/Applications/ComaxOrders/ComaxClientOrdersMain.vue'),
  },
  {
    path: '/comax_manage_orders_main',
    name: 'ComaxManageOrdersMain',
    component: () => import('../components/Applications/ComaxOrders/ComaxManageOrdersMain.vue'),
  },
  {
    path: '/calendar_manage',
    name: 'CalendarManage',
    component: () => import('../components/Applications/Calendar/components/MenageSide/CalendarManage.vue'),
  },
  {
    path: '/computer-errors-main',
    name: 'ComputerErrorsMain',
    component: () => import('../components/Applications/computer_errors/ComputerErrorsMain.vue'),
  },
  { //display form builded temporary
    path: '/preview_form',
    name: 'PreviewForm',
    component: () => import('../components/Applications/HumanResources/Admin/Components/Positions/Forms/Components/form_builder/view/PreviewForm.vue')
  },
  { //display specific form
    path: '/preview_specific_form/:id',
    name: 'PreviewSpecificForm',
    component: () => import('../components/Applications/HumanResources/Admin/Components/Positions/Forms/Components/form_builder/view/PreviewSpecificForm.vue'),
    props:true,
  },
  {
    path: '/bi',
    name: 'bi',
    component: () => import('../views/Bi.vue'),
    beforeEnter: async (to, from, next) => {
      store.state.loader = true;
      const role = await projectFunctions.httpsCallable('check_role')()
      if(role.data > 99){
        next()
        store.state.loader = false;
      }
      else{
        next({name: 'Home'})
        store.state.loader = false;
      }
    }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: () => import('../views/Admin/Admin.vue'),
    beforeEnter: async (to, from, next) => {
      store.state.loader = true;
      const role = await projectFunctions.httpsCallable('check_role')()
      if(role.data > 99){
        next()
        store.state.loader = false;
      }
      else{
        next({name: 'Home'})
        store.state.loader = false;
      }
    },
    children: [
      {
        path: 'users',
        name: 'Users',
        component: () => import('../views/Admin/Users.vue')
      },
      {
        path: 'priority',
        name: 'Priority',
        component: () => import('../views/Admin/Priority.vue')
      },
      {
        path: 'agents',
        name: 'agents',
        component: () => import('../views/Admin/Agents.vue')
      },
      {
        path: 'catalog',
        name: 'catalog',
        component: () => import('../views/Admin/Catalog.vue')
      },
      {
        path: 'shows',
        name: 'shows',
        component: () => import('../views/Admin/Shows.vue')
      },
      {
        path: 'sales',
        name: 'sales',
        component: () => import('../views/Admin/Sales.vue')
      },
      {
        path: 'goals',
        name: 'goals',
        component: () => import('../views/Admin/Goals.vue')
      },
      {
        path: 'zoe',
        name: 'zoe',
        component: () => import('../views/Admin/Zoe.vue')
      },
      {
        path: 'shivuk_rosman',
        name: 'shivuk_rosman',
        component: () => import('../views/Admin/ShivukRosman.vue')
      },
      {
        path: 'system',
        name: 'system',
        component: () => import('../views/Admin/System.vue')
      },
      {
        path: 'admin_applications',
        name: 'admin_applications',
        component: () => import('../views/Admin/AdminApplications.vue')
      },
      {
        path: 'human_resources',
        name: 'human_resources',
        component: () => import('../components/Applications/HumanResources/Admin/HumanResources.vue')
      },
    ]
  },
  {
    path: '/s2t',
    name: 's2t',
    component: () => import('../Ideas/S2T/S2T.vue')
  },
  {
    path: '/benshlush_dashboard',
    name: 'benshlush_dashboard',
    component: () => import('../views/BenShlush/BenshlushDashboard.vue'),
    meta: { title: 'Ben Shlush LTD Admin' },
  },
  {
    path: '/benshlush_sales',
    name: 'benshlush_sales',
    component: () => import('../views/BenShlush/BenshlushSales.vue'),
    meta: { title: 'Ben Shlush LTD',favicon:'https://firebasestorage.googleapis.com/v0/b/rosmannext.appspot.com/o/logos%2Fshlush_logo_375x375.jpg?alt=media&token=c33db170-87c8-44b6-ad6e-b55b9877c719' },
  },
  {
    path: '/computer-service/:id',
    name: 'computer-service',
    component: () => import('../components/Applications/computer_errors/components/Mashov/ComputerServiceMashov.vue'),
    props:true
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  // This will change the title for each route
  document.title = to.meta.title || 'Rosman Logistic';
  const defaultFavicon = 'path/to/default/favicon.ico';
  const faviconPath = `${to.meta.favicon || defaultFavicon}?v=${new Date().getTime()}`;
  
  // Find and update the favicon in the document head
  const link = document.querySelector("link[rel~='icon']");
  if (link) {
    link.href = faviconPath;
  } else {
    const newLink = document.createElement('link');
    newLink.rel = 'icon';
    newLink.href = faviconPath;
    document.head.appendChild(newLink);
  }
  next();
});

  // {
  //   path: '/register',
  //   name: 'Register',
  //   component: () => import('../views/Users/Register.vue')
  // },

export default router
